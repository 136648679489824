export {};

declare global {
    interface Object {
      toFormData(): FormData;
    }
  }
  
  // Step 2: Add the method to the Object prototype
  Object.prototype.toFormData = function (): FormData {
    const objectToFormData = (
      data: Record<string, any>, 
      formData: FormData = new FormData(), 
      parentKey: string | null = null
    ): FormData => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key];
          const formKey = parentKey ? `${parentKey}[${key}]` : key;
  
          if (value instanceof File || value instanceof Blob) {
            formData.append(formKey, value);
          } else if (value instanceof Date) {
            formData.append(formKey, value.toISOString());
          } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              objectToFormData({ [`${key}[${index}]`]: item }, formData, parentKey);
            });
          } else if (typeof value === 'object' && value !== null) {
            objectToFormData(value, formData, formKey);
          } else {
            formData.append(formKey, value);
          }
        }
      }
      
      return formData;
    };
  
    // `this` refers to the object on which `toFormData` is called
    return objectToFormData(this);
  };