import { AuthenticationService } from './../services/authentication.service';
import { forwardRef, Inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationState } from '../models/enums/authentication-state.enum';

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private injector: Injector
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						const body = event.body;
						if (body && body?.redirect && event.status === 200) {
							console.log('Redirect needed to:', body.redirect.destination);
							window.location.href = body.redirect.destination;
						} 
					}
				},
				(error) => {
					console.error('HTTP Error:', error);
					if (error.status === 401 && !request.url.includes('isAuthenticated')) {
						const authService = this.injector.get(AuthenticationService);
						authService.setAuthenticationState(AuthenticationState.CheckAuthSilent);
					}
				}
			)
		);
	}
}
