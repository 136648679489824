import { Injectable } from '@angular/core';
import { SystemService } from './system.service';
import { SystemInfo } from '../models/systeminfo';

@Injectable({
	providedIn: 'root'
})
export class SearchWidgetService {
	constructor(private systemService: SystemService) {}

	setSearchWidget() {
		this.systemService.$systemInfo.subscribe((response) => {
			let environment = response.environment.toLowerCase();
			if (environment === 'local') environment = 'development';
			const scriptElement = document.createElement('script');
			scriptElement.type = 'text/javascript';
			scriptElement.src =
				'https://intellichatwidget.z6.web.core.windows.net/content/js/search/' + environment + '/intellichat-searchwidget-internal.js';
			document.head.appendChild(scriptElement);
		});
	}
}
