import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AuthenticationState } from '../../../core/models/enums/authentication-state.enum';

@Component({
	template: `
		<div class="h-screen flex align-items-center justify-content-center">
			<div class="text-center w-10">
				<h2 class="mt-3">U bent uitgelogt.</h2>
				<p-button (click)="continue()" icon="bi bi-arrow-left" label="Inloggen"></p-button>
			</div>
		</div>
	`,
	styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router
	) {}

	continue(): void {
		this.router.navigate(['/']).then((_) => this.authenticationService.setAuthenticationState(AuthenticationState.CheckAuthenticated));
	}
}
