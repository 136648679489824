import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Component, OnInit } from '@angular/core';
import { ThemaService } from './core/services/theme.service';
import { timer } from 'rxjs';
import { SearchWidgetService } from './core/services/search-widget.service';
import { AppInsightsService } from './core/services/api/app-insights.service';

@Component({
	selector: 'app-root',
	template: `
		<p-toast position="top-right" [preventOpenDuplicates]="true"></p-toast>
		<p-confirmDialog></p-confirmDialog>
		@if (isThemeInitialized$ | async) {
			<router-outlet></router-outlet>
		} @else {
			<div class="fixed top-0 left-0 right-0 bottom-0 flex align-items-center justify-content-center">
				<div class="text-center">
					<h2 class="text-left">{{ spinnerText }}</h2>
					<app-spinner [size]="'xxl'"></app-spinner>
				</div>
			</div>
		}
	`
})
export class AppComponent implements OnInit {
	private readonly defaultSpinnerText = 'Aan het laden.';
	spinnerText = this.defaultSpinnerText;

	get isThemeInitialized$() {
		return this.themeService.isInitialized$;
	}

	constructor(
		private themeService: ThemaService,
		private searchWidgetService: SearchWidgetService,
		private _: AppInsightsService
	) {
		this.searchWidgetService.setSearchWidget();
	}
	ngOnInit(): void {
		timer(100, 500).subscribe(() => {
			if (this.spinnerText.endsWith('....')) {
				this.spinnerText = this.defaultSpinnerText;
				return;
			}
			this.spinnerText += '.';
		});
	}
}
