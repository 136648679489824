import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';

import { AuthenticationState } from '../../../core/models/enums/authentication-state.enum';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
	template: `
		<div class="h-screen flex align-items-center justify-content-center">
			<div class="text-center w-10">
				<h1 class="text-red-500 text-7xl">Error!</h1>
				@if (errorMessage.includes('AADB2C90027')) {
					<h2 class="text-red-500 fs-10">We konden u niet authenticeren. Mogelijk probeerde u op een verkeerde omgeving in te loggen.</h2>
				}
				<h4 class="text-red-500 mt-3">{{ errorMessage }}.</h4>

				<p-button (click)="continue()" icon="bi bi-arrow-left" label="Ga terug"></p-button>
			</div>
		</div>
	`,
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
	private params: ParamMap = undefined;

	get errorMessage(): string {
		return this.params.get('message') ?? 'Oeps! Er is iets mis gegaan';
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private router: Router
	) {
		this.activatedRoute.queryParamMap.subscribe((params) => (this.params = params));
	}

	continue(): void {
		this.router.navigate(['/']).then((_) => this.authenticationService.setAuthenticationState(AuthenticationState.CheckAuthenticated));
	}
}
