export {};

export enum SortDirection {
  Ascending,
  Descending
}

declare global {
  interface Date {
    toIso8601String(): string;    
  }
}

Date.prototype.toIso8601String = function(): string {
     var date =  Date.parse(this)
     if(date > 0) {
        return new Date(date).toISOString();
     }

    return null
};
