import { HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.isSecuredApi(request.url)) return next.handle(request);

		request = request.clone({
			headers: request.headers.set('x-force-cors-preflight', '') // Can be any value just have to check if its present.
		});

		return next.handle(request);
	}

	private isSecuredApi(url: string): boolean {
		return url.includes('/api');
	}
}
