import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { XsrfInterceptor } from './core/interceptors/xsrf.interceptor';
import { CoreModule } from './core/core.module';

import { ButtonModule } from 'primeng/button';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RedirectInterceptor } from './core/interceptors/redirect.interceptor';
import { ErrorComponent } from './pages/system/error/error.component';
import TimeAgo from 'javascript-time-ago';
import nl from 'javascript-time-ago/locale/nl';
import { SettingsDialogService } from './core/services/instellingen-dialog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { LoggedOutComponent } from './pages/system/loggedout/logged-out.component';

import './core/prototypes/array.prototypes';
import './core/prototypes/object.prototypes';
import './core/prototypes/date.prototypes';

import { MessageComponent } from './shared/components/message/message.component';
import { AuthenticationService } from './core/services/authentication.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from './core/services/api/app-insights.service';
import { SystemService } from './core/services/system.service';

@NgModule({
	declarations: [AppComponent, ErrorComponent, LoggedOutComponent],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		ConfirmDialogModule,
		BrowserModule,
		CoreModule,
		AppRoutingModule,
		RouterModule,
		BrowserAnimationsModule,
		ButtonModule,
		ToastModule,
		MessageComponent
	],
	providers: [
		AuthenticationService,
		AppInsightsService,
		SystemService,
		ConfirmationService,
		SettingsDialogService,
		DialogService,
		{ provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true },
		provideAnimations(),
		MessageService,
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: ErrorHandler,
			useClass: ApplicationinsightsAngularpluginErrorService
		}
	]
})
export class AppModule {
	constructor() {
		TimeAgo.addDefaultLocale(nl);
	}
}
