import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ErrorComponent } from './pages/system/error/error.component';
import { LoggedOutComponent } from './pages/system/loggedout/logged-out.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./layouts/frontend-layout/frontend-layout.routes').then((m) => m.FRONTEND_ROUTES)
	},
	{
		path: 'beheer',
		loadChildren: () => import('./layouts/backend-layout/backend-layout.routes').then((m) => m.BACKEND_ROUTES)
	},
	{
		path: 'loggedout',
		component: LoggedOutComponent
	},
	{
		path: 'error',
		component: ErrorComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
