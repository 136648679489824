import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidControlDirective } from '../shared/directives/invalid-control.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: []
})
export class CoreModule { }
