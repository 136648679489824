export default {
  "locale": "nl",
  "long": {
    "year": {
      "previous": "vorig jaar",
      "current": "dit jaar",
      "next": "volgend jaar",
      "past": "{0} jaar geleden",
      "future": "over {0} jaar"
    },
    "quarter": {
      "previous": "vorig kwartaal",
      "current": "dit kwartaal",
      "next": "volgend kwartaal",
      "past": {
        "one": "{0} kwartaal geleden",
        "other": "{0} kwartalen geleden"
      },
      "future": {
        "one": "over {0} kwartaal",
        "other": "over {0} kwartalen"
      }
    },
    "month": {
      "previous": "vorige maand",
      "current": "deze maand",
      "next": "volgende maand",
      "past": {
        "one": "{0} maand geleden",
        "other": "{0} maanden geleden"
      },
      "future": {
        "one": "over {0} maand",
        "other": "over {0} maanden"
      }
    },
    "week": {
      "previous": "vorige week",
      "current": "deze week",
      "next": "volgende week",
      "past": {
        "one": "{0} week geleden",
        "other": "{0} weken geleden"
      },
      "future": {
        "one": "over {0} week",
        "other": "over {0} weken"
      }
    },
    "day": {
      "previous": "gisteren",
      "previous-2": "eergisteren",
      "current": "vandaag",
      "next": "morgen",
      "next-2": "overmorgen",
      "past": {
        "one": "{0} dag geleden",
        "other": "{0} dagen geleden"
      },
      "future": {
        "one": "over {0} dag",
        "other": "over {0} dagen"
      }
    },
    "hour": {
      "current": "binnen een uur",
      "past": "{0} uur geleden",
      "future": "over {0} uur"
    },
    "minute": {
      "current": "binnen een minuut",
      "past": {
        "one": "{0} minuut geleden",
        "other": "{0} minuten geleden"
      },
      "future": {
        "one": "over {0} minuut",
        "other": "over {0} minuten"
      }
    },
    "second": {
      "current": "nu",
      "past": {
        "one": "{0} seconde geleden",
        "other": "{0} seconden geleden"
      },
      "future": {
        "one": "over {0} seconde",
        "other": "over {0} seconden"
      }
    }
  },
  "short": {
    "year": {
      "previous": "vorig jaar",
      "current": "dit jaar",
      "next": "volgend jaar",
      "past": "{0} jaar geleden",
      "future": "over {0} jaar"
    },
    "quarter": {
      "previous": "vorig kwartaal",
      "current": "dit kwartaal",
      "next": "volgend kwartaal",
      "past": "{0} kwart. geleden",
      "future": "over {0} kwart."
    },
    "month": {
      "previous": "vorige maand",
      "current": "deze maand",
      "next": "volgende maand",
      "past": {
        "one": "{0} maand geleden",
        "other": "{0} maanden geleden"
      },
      "future": {
        "one": "over {0} maand",
        "other": "over {0} maanden"
      }
    },
    "week": {
      "previous": "vorige week",
      "current": "deze week",
      "next": "volgende week",
      "past": {
        "one": "{0} week geleden",
        "other": "{0} weken geleden"
      },
      "future": {
        "one": "over {0} week",
        "other": "over {0} weken"
      }
    },
    "day": {
      "previous": "gisteren",
      "previous-2": "eergisteren",
      "current": "vandaag",
      "next": "morgen",
      "next-2": "overmorgen",
      "past": {
        "one": "{0} dag geleden",
        "other": "{0} dgn geleden"
      },
      "future": {
        "one": "over {0} dag",
        "other": "over {0} dgn"
      }
    },
    "hour": {
      "current": "binnen een uur",
      "past": "{0} uur geleden",
      "future": "over {0} uur"
    },
    "minute": {
      "current": "binnen een minuut",
      "past": "{0} min. geleden",
      "future": "over {0} min."
    },
    "second": {
      "current": "nu",
      "past": "{0} sec. geleden",
      "future": "over {0} sec."
    }
  },
  "narrow": {
    "year": {
      "previous": "vorig jaar",
      "current": "dit jaar",
      "next": "volgend jaar",
      "past": "{0} jaar geleden",
      "future": "over {0} jaar"
    },
    "quarter": {
      "previous": "vorig kwartaal",
      "current": "dit kwartaal",
      "next": "volgend kwartaal",
      "past": "{0} kw. geleden",
      "future": "over {0} kw."
    },
    "month": {
      "previous": "vorige maand",
      "current": "deze maand",
      "next": "volgende maand",
      "past": {
        "one": "{0} maand geleden",
        "other": "{0} maanden geleden"
      },
      "future": {
        "one": "over {0} maand",
        "other": "over {0} maanden"
      }
    },
    "week": {
      "previous": "vorige week",
      "current": "deze week",
      "next": "volgende week",
      "past": {
        "one": "{0} week geleden",
        "other": "{0} weken geleden"
      },
      "future": {
        "one": "over {0} week",
        "other": "over {0} weken"
      }
    },
    "day": {
      "previous": "gisteren",
      "previous-2": "eergisteren",
      "current": "vandaag",
      "next": "morgen",
      "next-2": "overmorgen",
      "past": {
        "one": "{0} dag geleden",
        "other": "{0} dgn geleden"
      },
      "future": {
        "one": "over {0} dag",
        "other": "over {0} dgn"
      }
    },
    "hour": {
      "current": "binnen een uur",
      "past": "{0} uur geleden",
      "future": "over {0} uur"
    },
    "minute": {
      "current": "binnen een minuut",
      "past": "{0} min. geleden",
      "future": "over {0} min."
    },
    "second": {
      "current": "nu",
      "past": "{0} sec. geleden",
      "future": "over {0} sec."
    }
  },
  "now": {
    "now": {
      "current": "nu",
      "future": "zometeen",
      "past": "zojuist"
    }
  },
  "mini": {
    "year": "{0} jr.",
    "month": "{0} mnd.",
    "week": {
      "one": "{0} week",
      "other": "{0} wkn."
    },
    "day": {
      "one": "{0} dag",
      "other": "{0} dgn."
    },
    "hour": "{0} uur",
    "minute": "{0} min.",
    "second": "{0} sec.",
    "now": "nu"
  },
  "long-time": {
    "year": {
      "one": "{0} jaar",
      "other": "{0} jaar"
    },
    "month": {
      "one": "{0} maand",
      "other": "{0} maanden"
    },
    "week": {
      "one": "{0} week",
      "other": "{0} weken"
    },
    "day": {
      "one": "{0} dag",
      "other": "{0} dagen"
    },
    "hour": {
      "one": "{0} uur",
      "other": "{0} uur"
    },
    "minute": {
      "one": "{0} minuut",
      "other": "{0} minuten"
    },
    "second": {
      "one": "{0} seconde",
      "other": "{0} seconden"
    }
  }
};