import { SystemService } from './../system.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, filter } from 'rxjs';
import { Rol } from '../../models/dtos/rollen/Rol';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { AuthenticationService } from '../authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AppInsightsService {
	private entityEndPoint = `/api/gebruikerrollen`;

	private isInitialised = false;

	constructor(
		private httpClient: HttpClient,
		private router: Router,
		private systemService: SystemService,
		private authService: AuthenticationService
	) {
		this.systemService.$systemInfo.pipe(filter((info) => !this.isInitialised && info != null)).subscribe((info) => {
			if (info.environment === 'Local') {
				return; // Dont log locally
			}
			this.authService.isAuthenticated$.pipe(filter((isAuthenticated) => isAuthenticated)).subscribe((_) => {
				const angularPlugin = new AngularPlugin();
				const appInsights = new ApplicationInsights({
					config: {
						connectionString: info.applicationInsightsConnectionString,
						enableAutoRouteTracking: true,
						enableCorsCorrelation: true,
						enableRequestHeaderTracking: true,
						enableResponseHeaderTracking: true,
						excludeRequestFromAutoTrackingPatterns: ['vimeo.com'],
						extensions: [angularPlugin],
						extensionConfig: {
							[angularPlugin.identifier]: { router: this.router }
						}
					}
				});

				appInsights.loadAppInsights();
				this.isInitialised = true;

				const gebruikerId = this.authService.claims.gebruikerId.toString();
				appInsights.setAuthenticatedUserContext(gebruikerId, gebruikerId);
			});
		});
	}

	getGebruikerRollen(gebruikerId: number): Observable<Rol[]> {
		var url = `${this.entityEndPoint}/${gebruikerId}`;
		return this.httpClient.get<Rol[]>(url);
	}

	upsertGebruikerRollen(gebruikerId: number, dtos: Rol[]): Observable<Rol[]> {
		var url = `${this.entityEndPoint}/${gebruikerId}/upsert`;
		return this.httpClient.post<Rol[]>(url, { gebruikerId, dtos });
	}
}
