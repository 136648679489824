import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemInfo } from '../models/systeminfo';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SystemService {
	private _$systemInfo = new BehaviorSubject<SystemInfo>(undefined);

	get $systemInfo() {
		return this._$systemInfo.asObservable();
	}

	constructor(private httpClient: HttpClient) {
		this.get().subscribe();
	}

	private get() {
		return this.httpClient.get<SystemInfo>('/api/system').pipe(tap((value) => this._$systemInfo.next(value)));
	}
}
